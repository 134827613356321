/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
function initMap() {
    var styles = [{
        "featureType": "water",
        "elementType": "all",
        "stylers": [{"hue": "#073381"}, {"saturation": 100}, {"lightness": -5}, {"visibility": "on"}]
    }, {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [{"hue": "#ffffff"}, {"saturation": -100}, {"lightness": 100}, {"visibility": "off"}]
    }, {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [{"hue": "#ffffff"}, {"saturation": 0}, {"lightness": 100}, {"visibility": "off"}]
    }, {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [{"hue": "#deecec"}, {"saturation": -73}, {"lightness": 72}, {"visibility": "on"}]
    }, {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [{"hue": "#073381"}, {"saturation": -100}, {"lightness": 25}, {"visibility": "on"}]
    }, {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{"hue": "#e3e3e3"}, {"saturation": -100}, {"lightness": 0}, {"visibility": "on"}]
    }, {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{"hue": "#ffffff"}, {"saturation": -100}, {"lightness": 100}, {"visibility": "simplified"}]
    }, {
        "featureType": "administrative",
        "elementType": "labels",
        "stylers": [{"hue": "#073381"}, {"saturation": 100}, {"lightness": 34}, {"visibility": "on"}]
    }];

    var mapElem = document.getElementById('map');
    var position = {
        lat: parseFloat(mapElem.getAttribute('data-lat')),
        lng: parseFloat(mapElem.getAttribute('data-lng'))
    };

    var map = new google.maps.Map(mapElem, {
        center: position,
        zoom: 15,
        scrollwheel: false,
        styles: styles
    });

    var markerProps = {
        map: map,
        position: position,
        title: 'Dr. Ducaud Zahnarzt'
    };

    if (mapElem.getAttribute('data-icon')) {
        markerProps.icon = document.getElementById('map').getAttribute('data-icon');
    }

    // Create a marker and set its position.
    var marker = new google.maps.Marker(markerProps);


}


(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {


                if (typeof(shownav) !== 'undefined') {

                    document.getElementById('shownav').addEventListener('click', showNavigation, false);

                }

                function showNavigation() {

                    document.getElementById('nav_mobil').style.display = "block";

                    Array.prototype.forEach.call(shownav.childNodes, function (element, index) {
                        element.className = 'fa fa-times';
                    });
                    document.getElementById('shownav').removeEventListener('click', showNavigation, false);
                    document.getElementById('shownav').addEventListener('click', hideNavigation, false);

                }

                function hideNavigation() {

                    document.getElementById('nav_mobil').style.display = "none";

                    Array.prototype.forEach.call(shownav.childNodes, function (element, index) {
                        element.className = 'fa fa-bars';
                    });

                    document.getElementById('shownav').removeEventListener('click', hideNavigation, false);
                    document.getElementById('shownav').addEventListener('click', showNavigation, false);

                }


                function changeBackground(scroll_pos) {
                    if (document.getElementById('BackgroundImage')) {
                        document.getElementById('BackgroundImage').style.backgroundPositionY = -(scroll_pos / 35) + "px";
                    }

                }

                var last_known_scroll_position = 0;
                var ticking = false;

                window.addEventListener('scroll', function (e) {
                    last_known_scroll_position = window.scrollY;
                    if (!ticking) {
                        window.requestAnimationFrame(function () {
                            changeBackground(last_known_scroll_position);
                            ticking = false;
                        });
                    }
                    ticking = true;
                });

                //Check to see if the window is top if not then display button
                $(window).scroll(function () {
                    if ($(this).scrollTop() > 100 && screen.width < 768) {
                        $('.scrollToTop').fadeIn();
                    } else {
                        $('.scrollToTop').fadeOut();
                    }
                });

                $('.videoModal').on('hide.bs.modal', function(e) {
                    var $if = $(e.delegateTarget).find('iframe');
                    var src = $if.attr("src");
                    $if.attr("src", '/empty.html');
                    $if.attr("src", src.replace('?autoplay=1&loop=1&autopause=0', ''));
                });

                $('.videoModal').on('shown.bs.modal', function(e) {
                    var $if = $(e.delegateTarget).find('iframe');
                    var src = $if.attr("src");
                    $if.attr("src", src + '?autoplay=1&loop=1&autopause=0');
                })

            }
        },

        // Praxis Page
        'page_template_praxis': {
            init: function () {
                var $grid = $('.gallery--js').masonry({
                    itemSelector: '.gallery__grid-item-js',
                    columnWidth: '.gallery__grid-sizer-js',
                    gutter: 40
                });

                $grid.imagesLoaded().progress(function () {
                    $grid.masonry();
                });

                lightbox.option({
                    'resizeDuration': 450,
                    'wrapAround': true
                });
            }
        },

        'page_template_team': {
            init: function() {
                $('.person--full-js').each(function() {
                    var $image = $(this).find('.person__image-js');
                    var $descr = $(this).find('.person__description-js');

                    if ($image.get(0).clientHeight > $descr.get(0).clientHeight) {
                        $descr.get(0).style.height = $image.get(0).clientHeight + "px";
                    }
                });
            }
        },

        // Angebot Page
        'page_template_angebot': {

            init: function () {


                /**
                 * Sets the height of the box in Angebote
                 */
                function box_Height($listElements) {
                    $listElements.find('.offers__entries-row-js').each(function () {
                        var maxHeight = 0;
                        $(this).find('.offers__entry-js').each(function () {
                            maxHeight = Math.max(maxHeight, this.clientHeight);
                        });

                        $(this).find('.offers__entry-js').each(function () {
                            if (!this.style.height) {
                                this.style.height = maxHeight + "px";
                            }
                        });
                    });
                }


                $('.offers__title-section-js').on('click', function (e) {
                    var $listElements = $(this).next();

                    if ($(this).hasClass('active')) {
                        $(this).removeClass('active');
                        $listElements.slideToggle(400);
                    } else {
                        $('.offers__title-section-js.active').next().slideToggle(400);
                        $('.offers__title-section-js.active').removeClass('active');

                        var $scrollElement = $(this);
                        $listElements.slideToggle(400, function () {
                            box_Height($listElements);
                            $('html, body').animate({
                                scrollTop: $scrollElement.offset().top
                            }, 400);
                        });
                        $(this).addClass('active');
                    }
                });


            },
            finalize: function () {
            }

        },
        'page_template_contact': {
            init: function () {
                var height = $('.map__figure-js').get(0).clientHeight;
                var addressHeight = $('.wysiwyg--address-js').get(0).clientHeight;
                var openingHoursHeight = $('.wysiwyg--opening-hours-js').get(0).clientHeight;

                var fullHeight = addressHeight + openingHoursHeight;

                var addressNewHeight = height * (addressHeight / fullHeight);
                var openingHoursNewHeight = height * (openingHoursHeight / fullHeight);

                if (height > fullHeight) {
                    $('.wysiwyg--address-js').get(0).style.height = addressNewHeight + "px";
                    $('.wysiwyg--opening-hours-js').get(0).style.height = openingHoursNewHeight + "px";
                } else {
                    $('.map__figure-js').get(0).style.height = fullHeight + "px";
                }
            }
        },

        'page_template_screen': {
            finalize: function () {
                var $list = $('.bxslider');

                $list.bxSlider({
                    mode: 'horizontal',
                    infiniteLoop: true,
                    auto: true,
                    autoStart: true,
                    autoDirection: 'next',
                    autoHover: true,
                    autoControls: false,
                    speed: 500,
                    pause: $list.attr('data-slider-pause'),
                    pager: false,
                    pagerType: 'full',
                    controls: false,
                    captions: true,
                    useCSS: true
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
